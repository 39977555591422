import * as React from "react";

import iconCookHat from './../img/cook-hat.svg'
import iconCookingPot from './../img/cooking-pot.svg'
import iconFacialTreatment from './../img/facial-treatment.svg'
import iconMaid from './../img/maid.svg'
import iconReceptionBell from './../img/reception-bell.svg'
import iconRoomService from './../img/room-service.svg'
import iconUserWithPc from './../img/user-in-front-of-computer.svg'
import iconWaiterDish from './../img/waiter-dish.svg'
import iconWaiterGlas from './../img/waiter-glas.svg'

interface IProp {
    iconName: string;
}
export function JobIcon(p: IProp) {
    let icon = null;

    switch (p.iconName) {

        case "CookHat":
            icon = iconCookHat;
            break;
        case "CookingPot":
            icon = iconCookingPot;
            break;
        case "FacialTreatment":
            icon = iconFacialTreatment
            break;
        case "Maid":
            icon = iconMaid;
            break;
        case "ReceptionBell":
            icon = iconReceptionBell
            break;
        case "RoomService":
            icon = iconRoomService
            break;
        case "UserWithPC":
            icon = iconUserWithPc;
            break;
        case "WaiterDish":
            icon = iconWaiterDish;
            break;
        case "WaiterGlas":
            icon = iconWaiterGlas;
            break;
    }


    if (icon === null) return null;

    return (
        <img className='size-full' src={icon} alt={p.iconName} />
    )
}