
import * as React from 'react'
import { IJobDTO } from '../models/IJob'
import { JobIcon } from './icons'
import { DisplayDateTime } from './displaydate'

import iconRightArrow from './../img/chevron-forward-outline.svg'
import { useParams } from 'react-router-dom'

import { FaBriefcase, FaClock, FaLocationDot } from 'react-icons/fa6'


interface IProps {
    slug: string
}
export function DefaultCard(p: IProps) {
    return (
        <div className="flex justify-between gap-x-6 mb-2 pt-5">
            <a className="size-full p-3 rounded-lg bg-white shadow" href={'/' + p.slug + '/application'}>
                <p className="text-sm font-bold">Nichts für dich dabei?</p>
                <p className="text-sm text-gray-800">Sende uns eine Spontanbewerbung und finde deinen Traumjob</p>
            </a>
        </div >
    )
}

interface IJobCard extends IProps {
    job: IJobDTO
}

export function JobCard(p: IJobCard) {

    const { id } = useParams();

    return (
        <li className="flex justify-between gap-x-6">
            {p.job.Priority &&
                <div className="test">
                    <div className="bookmark">
                        <img alt='icon favorite' src="images/icon-favorite.svg" />
                    </div>
                </div>
            }
            <a href={'/' + p.slug + '/' + p.job.Id} className={'size-full p-3 rounded hover:bg-gray-200 ' + (id === p.job.Id.toString() ? 'bg-white  ' : '')}>
                <div className="flex min-w-0 gap-x-4">
                    {p.job.IconName != null &&
                        <div className='h-12 w-12'>
                            <JobIcon iconName={p.job.IconName} />
                        </div>
                    }
                    <div className="flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{p.job.Title}</p>
                        <div className="mt-1 leading-5 text-gray-500">
                            <p className="text-sm align-center flex flex-row gap-1"><FaClock /> {p.job.ContractType}</p>
                            <p className="text-sm align-center flex flex-row gap-1"><FaBriefcase /> {p.job.Department}</p>
                            {p.job.Address != null &&
                                <p className="text-sm align-center flex flex-row gap-1"><FaLocationDot /> {p.job.Address}</p>
                            }
                        </div>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='flex flex-row justify-center align-center'>
                            <div className='size-5'>
                                <img src={iconRightArrow} alt='arrow right' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden flex justify-end flex-row">
                    <p className="text-xs text-gray-500">Online seit: <DisplayDateTime date={p.job.OnlineSince} showDate /></p>
                </div>
            </a>
        </li >
    )
}