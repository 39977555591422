import * as React from 'react'
import { IJobDTO } from '../models/IJob'
import { JobIcon } from './icons'
import { DisplayDateTime } from './displaydate'
import { useParams } from 'react-router-dom'
import { AppStateContext } from '../store/store'
import { FaBriefcase, FaClock, FaLocationDot } from 'react-icons/fa6'


export function JobDetail() {

    const appState = React.useContext(AppStateContext)
    const { id } = useParams();
    const [job, setJob] = React.useState<IJobDTO | null>(null);

    React.useEffect(() => {
        if (id === undefined) return;
        if (appState.jobs === undefined || appState.jobs === null) return;
        console.log('call useEffect in JobDetail')
        console.log(appState.jobs)

        let selectedJob = appState.jobs?.filter(x => x.Id.toString() === id);
        console.log('found jobs', selectedJob?.length)

        if (selectedJob != null && selectedJob.length > 0) {
            setJob(selectedJob[0]);
            console.log('found job')
        }
    }, [id, appState.jobs])

    if (job == null) return null;

    return (
        <div className="mb-5 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-lg shadow" style={{ display: job == null ? 'none' : '' }}>
            <div className="introduction">

                {job?.Priority &&
                    <div className="test">
                        <div className="bookmark">
                            {/* <img src="images/icon-favorite.svg" /> */}
                        </div>
                    </div>
                }

                <div className="">
                    <div className="flex flex-row gap-2">
                        {job?.IconName && job.IconName !== '' &&
                            <div className="w-14 shrink-0 grow-0 job-icon h-12 w-12">
                                <JobIcon iconName={job.IconName} />
                            </div>
                        }

                        <div className="titleDescription flex-auto">
                            <p className="text-2xl font-light text-gray-800">{job?.Title}</p>
                            <div className="text-sm flex flex-row flex-wrap content-start gap-x-3 gap-y-1">
                                <p className="gap-1 inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"><FaClock /> {job?.ContractType}</p>

                                <p className="gap-1 inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"><FaBriefcase /> {job?.Department}</p>

                                {job?.Address != null &&
                                    <p className="gap-1 inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"><FaLocationDot/> {job.Address}</p>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="hidden onlineSinceDiv">
                    <p className="onlineSince">Online seit: <DisplayDateTime date={job?.OnlineSince} showDate={true} /></p>
                </div>

                <div className="divider my-3 border-t border-gray-200"></div>

                <div className="descriptionEditorJS editorContentWrapper" dangerouslySetInnerHTML={{__html:job.DescriptionEditorJS}}></div>
                <div className="qualificationEditorJS editorContentWrapper" dangerouslySetInnerHTML={{__html:job.QualificationEditorJS}}></div>
                <div className="benefitEditorJS editorContentWrapper" dangerouslySetInnerHTML={{__html:job.BenefitEditorJS}}></div>
            </div>
        </div >
    )
}

